:root {
    /* Zumba Colors */
    --zumba-dark-coral: #D43361;
    --zumba-light-coral: #FF5E63;
    --zumba-dark-purple: #570073;
    --zumba-light-purple: #A82BEB;
    --zumba-plum: #BE2D93;
    --zumba-light-pink: #FF54AB;
    --zumba-pink: #E73E5D;
    --zumba-orange: #FA962C;
    --zumba-error-red: #D0000D;
    --zumba-neon-green: #C7FF00;
    --zumba-dark-green: #032B2E;
    --zumba-success-green: #2AB893;
    --zumba-dark-blue: #17009E;
    --zumba-light-blue: #3D12FF;


    --zumba-gray-800: #2C2D37;
    --zumba-gray-700: #353541;
    --zumba-gray-600: #484A55;
    --zumba-gray-500: #606B76;
    --zumba-gray-400: #95959A;
    --zumba-gray-300: #CACACD;
    --zumba-gray-200: #DADAE2;
    --zumba-gray-100: #F5F5F5;
    --zumba-gray-50: #F6F6F6;
    --zumba-white: #FFFFFF;

    --zumba-gray-500-rgb: 96, 107, 118;
    --zumba-gray-800-rgb: 44, 45, 55;

    --zumba-error-red-rgb: 208, 0, 13;
    --zumba-success-green-rgb: 42, 184, 147;
    --zumba-gray-400-rgb: 149, 149, 154;
	--zumba-dark-coral-dimmed: rgba(212, 51, 97, 0.9);
    --zumba-white-dimmed: rgba(255, 255, 255, 0.15);
    --zumba-light-blue-dimmed: rgba(61, 18, 255, 0.15);


    /* Strong Colors */
    /* Primary */
    --strong-razzmatazz: #FF0061; /* Deprecated, use strong-primary-coral instead */
    --strong-primary-coral: var(--strong-razzmatazz);
    --strong-primary-magenta: #F20194;
    --strong-primary-fuscia: #E301C7;
    --strong-primary-orange: #FF6148;
    --strong-primary-blue: #1921FA;
    --strong-primary-gray: #272A38;

    /* Secondary */
    --strong-secondary-dark-coral: #E80461;
    --strong-secondary-dark-magenta: #D9048C;
    --strong-secondary-dark-fuscia: #C203BF;
    --strong-secondary-dark-pink: #EC3C7F;
    --strong-secondary-dark-orange: #DA4A33;
    --strong-secondary-light-orange: #FF8A78;
    --strong-secondary-dark-blue: #0506C7;
    --strong-secondary-dark-gray: #181A21;

    /* Gradients */
    --strong-gradient-coral: linear-gradient(#FF0060, #CF0861);
    --strong-gradient-fuscia: linear-gradient(#E300C7, #A003BA);
    --strong-gradient-blue: linear-gradient(#1921FA, #030083);
    --strong-gradient-gray: linear-gradient(#282A38, #101218);

    /* Alerts */
    --strong-alerts-red: #D61A42;
    --strong-alerts-green: #2AB893;
    --strong-alerts-light-blue: #1EB4D6;

    /* Neutrals */
    --strong-neutral-gray-600: #241A21;
    --strong-neutral-gray-500: #484C54;
    --strong-neutral-gray-400: #95959B;
    --strong-neutral-gray-300: #878E9F;
    --strong-neutral-gray-200: #DADAE2;
    --strong-neutral-gray-100: #EFF0F7;
    --strong-neutral-white: #FFFFFF;

    --global-header-desktop-height: 5.125em;
    --global-header-mobile-height: 4.813em;

    --noom-orange: #FB513B;
    --noom-plum: #671D48;
    --noom-off-white: #F6F4EE;
}
