[v-cloak] {
    display: none;
}

.row {
    margin: 0 auto;
    max-width: 76.875rem;
    width: 100% !important;
}

.row:before, .row:after {
    content: " ";
    display: table;
}

.column, .columns {
    position: relative;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    float: left;
}

@media only screen and (min-width: 48rem) {
    .medium-8 {
        width: 66.6666666667%;
    }

    .medium-4 {
        width: 33.3333333333%;
    }
}

@media only screen and (min-width: 64rem) {
    .large-3 {
		width: 25%;
    }

    .large-9 {
        width: 75%;
    }
}
