/* Using full URL to avoid loading the same font multiple times in production while transitioning from Angular */
/* Zumba */
@font-face {
    font-family: 'Zandes Web';
    font-weight: 800;
    font-display: swap;
    src: url('https://www.zumba.com/fonts/zandes/andeszumba-extrabold-webfont.woff2') format('woff2'),
        url('https://www.zumba.com/fonts/zandes/andeszumba-extrabold-webfont.woff') format('woff');
}

@font-face {
    font-family: 'Zandes Web';
    font-weight: 700;
    font-display: swap;
    src: url('https://www.zumba.com/fonts/zandes/andeszumba-bold-webfont.woff2') format('woff2'),
        url('https://www.zumba.com/fonts/zandes/andeszumba-bold-webfont.woff') format('woff');
}

@font-face {
    font-family: 'Zandes Web';
    font-weight: 500;
    font-display: swap;
    src: url('https://www.zumba.com/fonts/zandes/andeszumba-medium-webfont.woff2') format('woff2'),
        url('https://www.zumba.com/fonts/zandes/andeszumba-medium-webfont.woff') format('woff');
}

@font-face {
    font-family: 'Zandes Web';
    font-weight: 400;
    font-display: swap;
    src: url('https://www.zumba.com/fonts/zandes/andeszumba-regular-webfont.woff2') format('woff2'),
        url('https://www.zumba.com/fonts/zandes/andeszumba-regular-webfont.woff') format('woff');
}

@font-face {
    font-family: 'Zandes Web';
    font-weight: 300;
    font-display: swap;
    src: url('https://www.zumba.com/fonts/zandes/andeszumba-book-webfont.woff2') format('woff2'),
        url('https://www.zumba.com/fonts/zandes/andeszumba-book-webfont.woff') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-weight: 400;
    font-display: swap;
    src: url('https://www.zumba.com/fonts/inter/Inter-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Inter';
    font-weight: 700;
    font-display: swap;
    src: url('https://www.zumba.com/fonts/inter/Inter-Bold.woff2') format('woff2');
}

:root {
    --zumba-font-default: "Zandes Web", Helvetica, Arial, sans-serif;
    --zumba-font-secondary: "Inter", Helvetica, Arial, sans-serif;
}

body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

body.theme-zumba,
input.theme-zumba,
textarea.theme-zumba,
button.theme-zumba {
    font-family: var(--zumba-font-default);
    font-size: 16px; /* define the rem size */
}

/* Headers */

.theme-zumba .z-h0.extra-bold,
.theme-zumba .z-h1.extra-bold,
.theme-zumba .z-h2.extra-bold,
.theme-zumba .z-h3.extra-bold,
.theme-zumba .z-h4.extra-bold,
.theme-zumba .z-h5.extra-bold,
.theme-zumba .z-h6.extra-bold {
    font-weight: 800;
}

.theme-zumba .z-h0,
.theme-zumba .z-h1,
.theme-zumba .z-h2,
.theme-zumba .z-h3,
.theme-zumba .z-h4,
.theme-zumba .z-h5,
.theme-zumba .z-h6 {
    margin: 0;
}

.theme-zumba .z-h0 {
    font-family: var(--zumba-font-default);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 3.6875rem;
    line-height: 4.0625rem;
}

.theme-zumba .z-h1 {
    font-family: var(--zumba-font-default);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 3.125rem;
    line-height: 3.375rem;
}

.theme-zumba h1,
.theme-zumba .z-h2 {
    font-family: var(--zumba-font-default);
    font-weight: 700;
    font-size: 2.625rem;
    line-height: 3.125rem;
}

.theme-zumba h2,
.theme-zumba .z-h3 {
    font-family: var(--zumba-font-default);
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 2.5rem;
}

.theme-zumba h3,
.theme-zumba .z-h4 {
    font-family: var(--zumba-font-default);
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.theme-zumba h4,
.theme-zumba .z-h5 {
    font-family: var(--zumba-font-default);
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.875rem;
}

.theme-zumba h5,
.theme-zumba .z-h6 {
    font-family: var(--zumba-font-default);
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.625rem;
}

@media screen and (max-width: 48rem) {
    .theme-zumba .z-h0 {
        font-size: 2.625rem;
        line-height: 3.125rem;
    }

    .theme-zumba .z-h1 {
        font-size: 2.625rem;
        line-height: 3.125rem;
    }

    .theme-zumba .z-h2 {
        font-size: 2.25rem;
        line-height: 2.5rem;
    }

    .theme-zumba .z-h3 {
        font-size: 2.25rem;
        line-height: 2.5rem;
    }

    .theme-zumba .z-h4 {
        font-size: 1.875rem;
        line-height: 2.25rem;
    }

    .theme-zumba .z-h5 {
        font-size: 1.5rem;
        line-height: 1.875rem;
    }

    .theme-zumba .z-h6 {
        font-size: 1.25rem;
        line-height: 1.625rem;
    }
}

/* Body */

.theme-zumba .z-b0 {
    font-size: 1.5rem;
    line-height: 1.875rem;
}

.theme-zumba .z-b1 {
    font-size: 1.25rem;
    line-height: 1.625rem;
}

.theme-zumba .z-b2 {
    font-size: 1.125rem;
    line-height: 1.625rem;
}

.theme-zumba p,
.theme-zumba .z-b3 {
    font-size: 1rem;
    line-height: 1.5rem;
}

.theme-zumba .z-b4 {
    font-size: .875rem;
    line-height: 1.25rem;
}

.theme-zumba .z-b5 {
    font-size: .750rem;
    line-height: 1.125rem;
}

.theme-zumba .z-b6 {
    font-size: 0.75rem;
    line-height: 1.125rem;
}

/* Body - Inter */
.theme-zumba .f-inter {
    font-family: Inter, "Zandes Web", Helvetica, Arial, sans-serif;
}

.theme-zumba p strong {
    font-family: inherit;
}

.text-uppercase {
    text-transform: uppercase;
}

/* Strong */
@font-face {
	font-family: 'Acherus';
	font-weight: 800;
	font-display: swap;
	src: url('https://strong.zumba.com/fonts/themed/strong/acherus-extrabold.eot');
	src: url('https://strong.zumba.com/fonts/themed/strong/acherus-extrabold.eot?#iefix') format('embedded-opentype'),
		url('https://strong.zumba.com/fonts/themed/strong/acherus-extrabold.woff') format('woff'),
		url('https://strong.zumba.com/fonts/themed/strong/acherus-extrabold.ttf') format('truetype');
}

@font-face {
	font-family: 'Acherus';
	font-weight: 700;
	font-display: swap;
	src: url('https://strong.zumba.com/fonts/themed/strong/acherus-bold.eot');
	src: url('https://strong.zumba.com/fonts/themed/strong/acherus-bold.eot?#iefix') format('embedded-opentype'),
		url('https://strong.zumba.com/fonts/themed/strong/acherus-bold.woff') format('woff'),
		url('https://strong.zumba.com/fonts/themed/strong/acherus-bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Acherus';
	font-weight: 500;
	font-display: swap;
	src: url('https://strong.zumba.com/fonts/themed/strong/acherus-medium.eot');
	src: url('https://strong.zumba.com/fonts/themed/strong/acherus-medium.eot?#iefix') format('embedded-opentype'),
		url('https://strong.zumba.com/fonts/themed/strong/acherus-medium.woff') format('woff'),
		url('https://strong.zumba.com/fonts/themed/strong/acherus-medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Acherus';
	font-weight: 400;
	font-display: swap;
	src: url('https://strong.zumba.com/fonts/themed/strong/acherus-regular.eot');
	src: url('https://strong.zumba.com/fonts/themed/strong/acherus-regular.eot?#iefix') format('embedded-opentype'),
		url('https://strong.zumba.com/fonts/themed/strong/acherus-regular.woff') format('woff'),
		url('https://strong.zumba.com/fonts/themed/strong/acherus-regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Uniform';
    font-weight: 500;
    src: url('https://strong.zumba.com/fonts/themed/strong/UniformExtraCondensed-Medium-webfont.eot');
	src: url('https://strong.zumba.com/fonts/themed/strong/UniformExtraCondensed-Medium-webfont.eot?#iefix') format('embedded-opentype'),
		url('https://strong.zumba.com/fonts/themed/strong/UniformExtraCondensed-Medium-webfont.woff') format('woff'),
		url('https://strong.zumba.com/fonts/themed/strong/UniformExtraCondensed-Medium-webfont.ttf') format('truetype');
}

:root {
	--strong-font-default: "Acherus", Helvetica, Arial, sans-serif;
	--strong-font-uniform: "Uniform", Helvetica, Arial, sans-serif;
}

body.theme-strong,
input.theme-strong,
textarea.theme-strong,
button.theme-strong {
    font-family: var(--strong-font-default);
    font-size: 16px; /* define the rem size */
}

.theme-strong .z-h0 {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 4.375rem;
    line-height: 4.75rem;
    font-family: var(--strong-font-uniform);
}

.theme-strong .z-h1 {
    font-weight: 500;
    font-size: 3.125rem;
    line-height: 3.5rem;
    font-family: var(--strong-font-uniform);
}

.theme-strong .z-h2 {
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 2.875rem;
    font-family: var(--strong-font-uniform);
}

.theme-strong .z-h3 {
    font-weight: 500;
    font-size: 2.25rem;
    line-height: 2.625rem;
    font-family: var(--strong-font-uniform);
}

.theme-strong .z-h4 {
    font-weight: 500;
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-family: var(--strong-font-uniform);
}

.theme-strong .z-h5 {
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 2.125rem;
    font-family: var(--strong-font-uniform);
}

.theme-strong .z-h6 {
    font-weight: 500;
    font-size: 1.625rem;
    line-height: 2rem;
    font-family: var(--strong-font-uniform);
}

@media screen and (max-width: 48rem) {
    .theme-strong .z-h0 {
        font-size: 3.125rem;
        line-height: 3.5rem;
    }

    .theme-strong .z-h1 {
        font-size: 2.875rem;
        line-height: 3.25rem;
    }

    .theme-strong .z-h2 {
        font-size: 2.5rem;
        line-height: 2.875rem;
    }

    .theme-strong .z-h3 {
        font-size: 2.25rem;
        line-height: 2.625rem;
    }

    .theme-strong .z-h4 {
        font-size: 1.875rem;
        line-height: 2.25rem;
    }

    .theme-strong .z-h5 {
        font-size: 1.75rem;
        line-height: 2.125rem;
    }

    .theme-strong .z-h6 {
        font-size: 1.625rem;
        line-height: 2rem;
    }
}

.theme-strong .z-b0 {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-family: var(--strong-font-default);
}

.theme-strong .z-b1 {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.625rem;
    font-family: var(--strong-font-default);
}

.theme-strong .z-b2 {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.625rem;
    font-family: var(--strong-font-default);
}

.theme-strong .z-b3 {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: var(--strong-font-default);
}

.theme-strong .z-b4 {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-family: var(--strong-font-default);
}

.theme-strong .z-b5 {
    font-size: .75rem;
    line-height: 1rem;
    font-weight: 400;
    font-family: var(--strong-font-default);
}

.theme-strong .z-b6 {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    font-family: var(--strong-font-default);
}

.theme-strong .z-bl {
    font-weight: 700;
    font-size: 1rem;
    line-height: 2.5rem;
    text-transform: uppercase;
    font-family: var(--strong-font-default);
}

.theme-strong .z-bm {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.625rem;
    text-transform: uppercase;
    font-family: var(--strong-font-default);
}

.theme-strong .f-uniform {
    font-family: var(--strong-font-uniform);
}

.theme-strong .f-inter {
    font-family: unset;
}

.theme-zumba p strong {
    font-family: inherit;
}